// ---- zie https://medium.com/ampersand-academy/ionic-4-modal-example-da9694fa0f53
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Form, Validators } from '@angular/forms';
import { OverlayEventDetail } from '@ionic/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import * as Editor from '@ckeditor/ckeditor5-build-inline';

import { AjaxService } from '../ajax.service';
import { GlobalService } from '../global.service';
import { ScanCameraPage } from '../scan-camera/scan-camera.page';

interface RecordSpec {
    fld: any;
    value: any;
    descr?: string;
    typ?: string;
    tbl?: string;
    exe?: string;
    con?: string;
    valid?: boolean;
    msg?: string;
    choices?: any[];
    EditCond?: boolean;
    minDate?: Date;
    opt?: string;
    btn?: string;
}

@Component({
    selector: 'app-record-form',
    templateUrl: './record-form.page.html',
    styleUrls: ['./record-form.page.scss'],
})
export class RecordFormPage implements OnInit {

    defaults: [];
    recordFields: RecordSpec[] = [];
    table: '';
    tableDescr: '';
    id: '*NEW*';
    formValid = false;
    scanCamera = false;

    public Editor:any = Editor;

    constructor(
        public viewCtrl: ModalController,
        private navParams: NavParams,
        private router: Router,
        public global: GlobalService,
        public alertController: AlertController,
        public modalController: ModalController,
        private ajax: AjaxService,
    ) {
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
        console.log('ionViewWillEnter');
        this.defaults = this.navParams.get('defaults');
        this.table = this.navParams.get('table');
        this.id = this.navParams.get('id');
        this.ajax.get('recordForm', {
            table: this.table,
            id: this.id,
            defaults: this.defaults,
        })
            .then((data: any) => {
                console.log('recordFields', data);
                if (!data.login) {
                    this.viewCtrl.dismiss();
                } else {
                    data.recordFields.forEach((elt: RecordSpec, i) => {
                        if (!('valid' in data.recordFields[i])) {
                            data.recordFields[i].valid = true;
                        }
                        if (data.recordFields[i].opt.includes('limit')) {
                            const opts = data.recordFields[i].opt.split(';');
                            const minLimit: Date = new Date();
                            minLimit.setDate(minLimit.getDate() - opts[1]);
                            data.recordFields[i].minDate = minLimit;
                        } else {
                            data.recordFields[i].minDate = null;
                        }
                    });
                    this.recordFields = data.recordFields;
                    this.tableDescr = data.tableDescr;
                    this.checkForm();
                    this.evalForm();
                }
            });
    }

    customTrackBy(index: number, obj: any): any {
        return index;
    }

    doCancel() {
        this.alertController.create({
            header: 'Bevestig cancel',
            message: 'Invoer stoppen?',
            buttons: [
              {
                text: 'Nee',
                handler: () => {
                  console.log('in dialoog blijven');
                }
              },
              {
                text: 'OK',
                handler: () => {
                    this.viewCtrl.dismiss();
                }
              }
            ]
          }).then(res => {
            res.present();
          });
    }

    doSubmit() {
        this.evalForm();
        if (this.formValid) {
            this.ajax.get('recordSave', {
                table: this.table,
                fields: this.recordFields
            })
                .then((data: any) => {
                    this.viewCtrl.dismiss(data);
                });
        }
    }

    checkForm() {
        let myCheck = true;
        this.recordFields.forEach((elt: RecordSpec, i) => {
            myCheck = myCheck && this.recordFields[i].valid;
        });
        this.formValid = myCheck;
    }

    /** bij elke wijziging in formulier uit te voeren */
    evalForm() {
        let answers = '';
        for (const rec of this.recordFields) {
            const val = (typeof rec.value === 'string') && (rec.typ !== 'N') ? `'${rec.value}'` :
                (rec.value) ? rec.value : `''`;
            answers = answers + `let ${rec.fld}=${val}; `; // hier komt het invul-antwoord
            if (rec.exe && rec.exe.length > 0) {
                console.log('Executing: ', rec.exe);
                try {
                    // tslint:disable-next-line: no-eval
                    eval(rec.exe);
                } catch (err) {
                    console.log('catch executing ', rec.exe, err);
                }
            }
        }
        console.log('answers:', answers);

        for (const rec of this.recordFields) {
            let msg = '';
            if (rec.con.length > 0) {
                try {
                    // tslint:disable-next-line: no-eval
                    msg = eval(`(function() { let _retv = true; ${answers} _retv=(${rec.con}); return _retv; })();`);
                } catch (err) {
                    console.error(err);
                }
            }
            if (rec.typ === 'D') {

            }
            rec.valid = (!msg);
            rec.msg = msg;
            console.log('msg:', msg);
            if (rec.fld === 'newID' && msg === '') {
                // speciale extra condities voor een nieuw ID veld
                if (rec.value && (rec.typ !== 'N')) {
                    rec.value = rec.value.replace(/[^A-Za-z0-9_-]+/g, '_');
                }
                this.ajax.get('exists', {
                    tbl: this.table,
                    val: rec.value
                }).then((data: any) => {
                    rec.valid = (!data.exists);
                    rec.msg = (data.exists) ? 'Bestaat al.' : '';
                    this.checkForm();
                });
            }
        }
        this.checkForm();
    }

    comboChanged(event, index: number) {
        console.log('comboChanged:', event, index);
        this.ajax.get('comboChoices', {
            table: this.recordFields[index].opt,
            txt: event
        })
            .then((data: any) => {
                console.log('comboChoices:', data);
                this.recordFields[index].choices = (data.choices) ? data.choices : [];
                // this.recordFields[index]['valid'] = (this.recordFields[index]['choices'].length>0);
                this.recordFields[index].valid = this.recordFields[index].choices.find(e => e.ID === this.recordFields[index].value);
                this.checkForm();
            });
    }

    async doScan(i) {
        this.scanCamera = true;
        const myDlg = await this.modalController.create({
            component: ScanCameraPage,
        });
        myDlg.onDidDismiss()
        .then((dataPar: OverlayEventDetail) => {
            this.scanCamera = false;
            if (dataPar.data.code) {
                this.recordFields[i].value = dataPar.data.code;
                this.evalForm();
            } else {
                this.recordFields[i].value = '';
                this.evalForm();
            }
        });
        return await myDlg.present();
    }
}
