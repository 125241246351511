import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {
    public Version = '3.4.12';
    // zie ook 2x versie in android/app/build.gradle
    // en ios/App/App.xcodeproj/project.pbxproj

    public latestVersion = '';
    public apkDownload = false;
    public MyPlatform = 'android'; // 'android'; // 'ios';
    public App = 'BAR3';
    public devID = '';
    public devInfo = '';

    public baseurl = 'https://cloud.lisaas.com/lipronics/';

    public isLoggedIn = false;
    public compname: string;
    public username: string;
    public password: string;
    public usrvalue: string;
    public usrlevel: number;
    public session: string;
    public uuid: string;
    public var: string[];
    public modules: string[];
    public language = 'nl';
    public txt: string[]; // vertalingen worden hierin geladen

    public optHideScanBtn: boolean;
    public optAfbV2: boolean;
    public startPage = '/home';
    public optInclBtw: boolean = false;

    public currArtNr: string;
    public currProjNr: string;

    constructor() { }

    getvar(varname: string, defaultValue='') {
        if (this.var && varname in this.var) {
            return this.var[varname];
        } else {
            return defaultValue;
        }
    }

    getmod(module: string) {
        if (this.modules) {
            if (this.modules[module]) {
                return this.modules[module];
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }
}
